import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import Updates from './Updates';


function Category() {
	const { id } = useParams();
	const history = useHistory();

	const categories = [
		"samp",
		"gtav",
		"web",
		"other"
	];

	if (!categories.includes(id)) {
		setTimeout(() => history.push("/"), 2500);
		return (
			<div className="container mx-auto text-center">
				<Header />
				<h1 style={{
					fontSize: "26px"
				}}>404 - Not Found.</h1>
				<p>Navigating you back to home...</p>
				
				<Footer />

			</div>
		)
	}

	return (
		<div className="container mx-auto">
			<Header category={id} />
			<Updates category={id} />
			<Footer />

		</div>
	);
}

export default Category;