import React from 'react';
import { Link, useParams } from 'react-router-dom'; 

import Logo from '../assets/img/logo.png';

import "./Header.css";

function Header ({ category }) {

    const { id } = useParams();

    function getCategory(id) {
        switch (id) {
            case "samp":
                return "SA-MP Server Development";
            case "gtav":
                return "GTA V Server Development";
            case "web":
                return "Website Services";
            case "other":
                return "Other Development Logs";
            default:
                return "Summary Page"

        }
    }

    const isActive = (c, d) => ((d === undefined || d === null) && c === "home") ? true : d === c;

    return (
        <div className="flex justify-center items-center flex-col mx-5 my-10">
            <a href="https://nlvl.net" target="_blank" rel="noopener noreferrer">
                <img className="h-32 mb-5" src={Logo} alt="nlvl" />
            </a>
            <h1 className="m-rep text-3xl text-black dark:text-white font-black uppercase">NLVL Development Changelog</h1>
            <h2 className="m-rep text-1xl text-black dark:text-white font-black uppercase">{getCategory(category)}</h2>

            <div className="navigation">
                <Link className={isActive("home", id) ? "item text-black dark:text-white uppercase font-black" : "item text-black dark:text-white uppercase"} to="/">HOME</Link>
                <Link className={isActive("samp", id) ? "item text-black dark:text-white uppercase font-black" : "item text-black dark:text-white uppercase"} to="/category/samp">SA-MP</Link>
                <Link className={isActive("gtav", id) ? "item text-black dark:text-white uppercase font-black" : "item text-black dark:text-white uppercase"} to="/category/gtav">GTA V</Link>
                <Link className={isActive("web", id) ? "item text-black dark:text-white uppercase font-black" : "item text-black dark:text-white uppercase"} to="/category/web">WEB DEVELOPMENT</Link>
                <Link className={isActive("other", id) ? "item text-black dark:text-white uppercase font-black" : "item text-black dark:text-white uppercase"} to="/category/other">OTHER</Link>
            </div>
            
        </div>
    );
}

export default Header;