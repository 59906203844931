import React from 'react';

import Logo from '../assets/img/logo.png';

function Footer () {
    const year = new Date().getFullYear();
    return (
        <div className="flex justify-center items-center flex-col mx-5 my-10 mt-20">
            <a href="https://nlvl.net" target="_blank" rel="noopener noreferrer">
                <img className="h-16 mb-5" src={Logo} alt="nlvl" />
            </a>
            <h1 className="text-md text-black dark:text-white font-black uppercase">NLVL Development Changelog</h1>
            <p className="text-md text-black dark:text-white font-light">&copy; NLVL 2019 - {year}</p>
        </div>
    );
}

export default Footer;