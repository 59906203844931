import Header from './Header';
import Footer from './Footer';
import Updates from './Updates';

function Changelog() {
  return (
    <div className="container mx-auto">
        <Header />
        <Updates category={"all"} />       
        <Footer />
    </div>
  );
}

export default Changelog;