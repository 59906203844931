import React from 'react';

function AllColors() {
    return ( 
        <div className="grid md:grid-cols-10 xs:grid-cols-3 sm:grid-cols-3 xl:grid-cols-10 gap-5 text-center">
            <div className="bg-bluegray-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>bluegray 50</code>
            </div>
            <div className="bg-bluegray-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>bluegray 100</code>
            </div>
            <div className="bg-bluegray-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>bluegray 200</code>
            </div>
            <div className="bg-bluegray-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>bluegray 300</code>
            </div>
            <div className="bg-bluegray-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>bluegray 400</code>
            </div>
            <div className="bg-bluegray-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>bluegray 500</code>
            </div>
            <div className="bg-bluegray-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>bluegray 600</code>
            </div>
            <div className="bg-bluegray-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>bluegray 700</code>
            </div>
            <div className="bg-bluegray-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>bluegray 800</code>
            </div>
            <div className="bg-bluegray-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>bluegray 900</code>
            </div>

            <div className="bg-coolgray-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>coolgray 50</code>
            </div>
            <div className="bg-coolgray-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>coolgray 100</code>
            </div>
            <div className="bg-coolgray-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>coolgray 200</code>
            </div>
            <div className="bg-coolgray-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>coolgray 300</code>
            </div>
            <div className="bg-coolgray-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>coolgray 400</code>
            </div>
            <div className="bg-coolgray-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>coolgray 500</code>
            </div>
            <div className="bg-coolgray-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>coolgray 600</code>
            </div>
            <div className="bg-coolgray-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>coolgray 700</code>
            </div>
            <div className="bg-coolgray-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>coolgray 800</code>
            </div>
            <div className="bg-coolgray-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>coolgray 900</code>
            </div>

            <div className="bg-gray-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>gray 50</code>
            </div>
            <div className="bg-gray-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>gray 100</code>
            </div>
            <div className="bg-gray-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>gray 200</code>
            </div>
            <div className="bg-gray-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>gray 300</code>
            </div>
            <div className="bg-gray-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>gray 400</code>
            </div>
            <div className="bg-gray-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>gray 500</code>
            </div>
            <div className="bg-gray-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>gray 600</code>
            </div>
            <div className="bg-gray-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>gray 700</code>
            </div>
            <div className="bg-gray-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>gray 800</code>
            </div>
            <div className="bg-gray-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>gray 900</code>
            </div>

            <div className="bg-truegray-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>truegray 50</code>
            </div>
            <div className="bg-truegray-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>truegray 100</code>
            </div>
            <div className="bg-truegray-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>truegray 200</code>
            </div>
            <div className="bg-truegray-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>truegray 300</code>
            </div>
            <div className="bg-truegray-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>truegray 400</code>
            </div>
            <div className="bg-truegray-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>truegray 500</code>
            </div>
            <div className="bg-truegray-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>truegray 600</code>
            </div>
            <div className="bg-truegray-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>truegray 700</code>
            </div>
            <div className="bg-truegray-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>truegray 800</code>
            </div>
            <div className="bg-truegray-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>truegray 900</code>
            </div>

            <div className="bg-warmgray-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>warmgray 50</code>
            </div>
            <div className="bg-warmgray-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>warmgray 100</code>
            </div>
            <div className="bg-warmgray-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>warmgray 200</code>
            </div>
            <div className="bg-warmgray-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>warmgray 300</code>
            </div>
            <div className="bg-warmgray-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>warmgray 400</code>
            </div>
            <div className="bg-warmgray-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>warmgray 500</code>
            </div>
            <div className="bg-warmgray-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>warmgray 600</code>
            </div>
            <div className="bg-warmgray-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>warmgray 700</code>
            </div>
            <div className="bg-warmgray-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>warmgray 800</code>
            </div>
            <div className="bg-warmgray-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>warmgray 900</code>
            </div>

            <div className="bg-red-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>red 50</code>
            </div>
            <div className="bg-red-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>red 100</code>
            </div>
            <div className="bg-red-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>red 200</code>
            </div>
            <div className="bg-red-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>red 300</code>
            </div>
            <div className="bg-red-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>red 400</code>
            </div>
            <div className="bg-red-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>red 500</code>
            </div>
            <div className="bg-red-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>red 600</code>
            </div>
            <div className="bg-red-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>red 700</code>
            </div>
            <div className="bg-red-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>red 800</code>
            </div>
            <div className="bg-red-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>red 900</code>
            </div>

            <div className="bg-orange-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>orange 50</code>
            </div>
            <div className="bg-orange-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>orange 100</code>
            </div>
            <div className="bg-orange-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>orange 200</code>
            </div>
            <div className="bg-orange-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>orange 300</code>
            </div>
            <div className="bg-orange-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>orange 400</code>
            </div>
            <div className="bg-orange-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>orange 500</code>
            </div>
            <div className="bg-orange-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>orange 600</code>
            </div>
            <div className="bg-orange-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>orange 700</code>
            </div>
            <div className="bg-orange-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>orange 800</code>
            </div>
            <div className="bg-orange-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>orange 900</code>
            </div>

            <div className="bg-amber-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>amber 50</code>
            </div>
            <div className="bg-amber-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>amber 100</code>
            </div>
            <div className="bg-amber-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>amber 200</code>
            </div>
            <div className="bg-amber-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>amber 300</code>
            </div>
            <div className="bg-amber-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>amber 400</code>
            </div>
            <div className="bg-amber-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>amber 500</code>
            </div>
            <div className="bg-amber-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>amber 600</code>
            </div>
            <div className="bg-amber-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>amber 700</code>
            </div>
            <div className="bg-amber-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>amber 800</code>
            </div>
            <div className="bg-amber-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>amber 900</code>
            </div>

            <div className="bg-yellow-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>yellow 50</code>
            </div>
            <div className="bg-yellow-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>yellow 100</code>
            </div>
            <div className="bg-yellow-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>yellow 200</code>
            </div>
            <div className="bg-yellow-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>yellow 300</code>
            </div>
            <div className="bg-yellow-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>yellow 400</code>
            </div>
            <div className="bg-yellow-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>yellow 500</code>
            </div>
            <div className="bg-yellow-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>yellow 600</code>
            </div>
            <div className="bg-yellow-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>yellow 700</code>
            </div>
            <div className="bg-yellow-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>yellow 800</code>
            </div>
            <div className="bg-yellow-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>yellow 900</code>
            </div>

            <div className="bg-lime-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>lime 50</code>
            </div>
            <div className="bg-lime-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>lime 100</code>
            </div>
            <div className="bg-lime-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>lime 200</code>
            </div>
            <div className="bg-lime-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>lime 300</code>
            </div>
            <div className="bg-lime-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>lime 400</code>
            </div>
            <div className="bg-lime-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>lime 500</code>
            </div>
            <div className="bg-lime-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>lime 600</code>
            </div>
            <div className="bg-lime-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>lime 700</code>
            </div>
            <div className="bg-lime-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>lime 800</code>
            </div>
            <div className="bg-lime-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>lime 900</code>
            </div>

            <div className="bg-green-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>green 50</code>
            </div>
            <div className="bg-green-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>green 100</code>
            </div>
            <div className="bg-green-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>green 200</code>
            </div>
            <div className="bg-green-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>green 300</code>
            </div>
            <div className="bg-green-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>green 400</code>
            </div>
            <div className="bg-green-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>green 500</code>
            </div>
            <div className="bg-green-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>green 600</code>
            </div>
            <div className="bg-green-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>green 700</code>
            </div>
            <div className="bg-green-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>green 800</code>
            </div>
            <div className="bg-green-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>green 900</code>
            </div>

            <div className="bg-emerald-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>emerald 50</code>
            </div>
            <div className="bg-emerald-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>emerald 100</code>
            </div>
            <div className="bg-emerald-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>emerald 200</code>
            </div>
            <div className="bg-emerald-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>emerald 300</code>
            </div>
            <div className="bg-emerald-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>emerald 400</code>
            </div>
            <div className="bg-emerald-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>emerald 500</code>
            </div>
            <div className="bg-emerald-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>emerald 600</code>
            </div>
            <div className="bg-emerald-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>emerald 700</code>
            </div>
            <div className="bg-emerald-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>emerald 800</code>
            </div>
            <div className="bg-emerald-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>emerald 900</code>
            </div>

            <div className="bg-teal-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>teal 50</code>
            </div>
            <div className="bg-teal-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>teal 100</code>
            </div>
            <div className="bg-teal-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>teal 200</code>
            </div>
            <div className="bg-teal-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>teal 300</code>
            </div>
            <div className="bg-teal-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>teal 400</code>
            </div>
            <div className="bg-teal-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>teal 500</code>
            </div>
            <div className="bg-teal-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>teal 600</code>
            </div>
            <div className="bg-teal-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>teal 700</code>
            </div>
            <div className="bg-teal-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>teal 800</code>
            </div>
            <div className="bg-teal-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>teal 900</code>
            </div>

            <div className="bg-cyan-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>cyan 50</code>
            </div>
            <div className="bg-cyan-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>cyan 100</code>
            </div>
            <div className="bg-cyan-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>cyan 200</code>
            </div>
            <div className="bg-cyan-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>cyan 300</code>
            </div>
            <div className="bg-cyan-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>cyan 400</code>
            </div>
            <div className="bg-cyan-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>cyan 500</code>
            </div>
            <div className="bg-cyan-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>cyan 600</code>
            </div>
            <div className="bg-cyan-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>cyan 700</code>
            </div>
            <div className="bg-cyan-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>cyan 800</code>
            </div>
            <div className="bg-cyan-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>cyan 900</code>
            </div>

            <div className="bg-sky-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>sky 50</code>
            </div>
            <div className="bg-sky-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>sky 100</code>
            </div>
            <div className="bg-sky-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>sky 200</code>
            </div>
            <div className="bg-sky-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>sky 300</code>
            </div>
            <div className="bg-sky-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>sky 400</code>
            </div>
            <div className="bg-sky-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>sky 500</code>
            </div>
            <div className="bg-sky-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>sky 600</code>
            </div>
            <div className="bg-sky-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>sky 700</code>
            </div>
            <div className="bg-sky-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>sky 800</code>
            </div>
            <div className="bg-sky-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>sky 900</code>
            </div>

            <div className="bg-blue-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>blue 50</code>
            </div>
            <div className="bg-blue-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>blue 100</code>
            </div>
            <div className="bg-blue-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>blue 200</code>
            </div>
            <div className="bg-blue-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>blue 300</code>
            </div>
            <div className="bg-blue-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>blue 400</code>
            </div>
            <div className="bg-blue-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>blue 500</code>
            </div>
            <div className="bg-blue-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>blue 600</code>
            </div>
            <div className="bg-blue-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>blue 700</code>
            </div>
            <div className="bg-blue-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>blue 800</code>
            </div>
            <div className="bg-blue-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>blue 900</code>
            </div>

            <div className="bg-indigo-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>indigo 50</code>
            </div>
            <div className="bg-indigo-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>indigo 100</code>
            </div>
            <div className="bg-indigo-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>indigo 200</code>
            </div>
            <div className="bg-indigo-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>indigo 300</code>
            </div>
            <div className="bg-indigo-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>indigo 400</code>
            </div>
            <div className="bg-indigo-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>indigo 500</code>
            </div>
            <div className="bg-indigo-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>indigo 600</code>
            </div>
            <div className="bg-indigo-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>indigo 700</code>
            </div>
            <div className="bg-indigo-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>indigo 800</code>
            </div>
            <div className="bg-indigo-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>indigo 900</code>
            </div>


            <div className="bg-violet-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>violet 50</code>
            </div>
            <div className="bg-violet-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>violet 100</code>
            </div>
            <div className="bg-violet-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>violet 200</code>
            </div>
            <div className="bg-violet-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>violet 300</code>
            </div>
            <div className="bg-violet-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>violet 400</code>
            </div>
            <div className="bg-violet-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>violet 500</code>
            </div>
            <div className="bg-violet-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>violet 600</code>
            </div>
            <div className="bg-violet-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>violet 700</code>
            </div>
            <div className="bg-violet-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>violet 800</code>
            </div>
            <div className="bg-violet-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>violet 900</code>
            </div>

            <div className="bg-purple-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>purple 50</code>
            </div>
            <div className="bg-purple-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>purple 100</code>
            </div>
            <div className="bg-purple-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>purple 200</code>
            </div>
            <div className="bg-purple-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>purple 300</code>
            </div>
            <div className="bg-purple-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>purple 400</code>
            </div>
            <div className="bg-purple-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>purple 500</code>
            </div>
            <div className="bg-purple-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>purple 600</code>
            </div>
            <div className="bg-purple-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>purple 700</code>
            </div>
            <div className="bg-purple-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>purple 800</code>
            </div>
            <div className="bg-purple-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>purple 900</code>
            </div>

            <div className="bg-fuchsia-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>fuchsia 50</code>
            </div>
            <div className="bg-fuchsia-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>fuchsia 100</code>
            </div>
            <div className="bg-fuchsia-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>fuchsia 200</code>
            </div>
            <div className="bg-fuchsia-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>fuchsia 300</code>
            </div>
            <div className="bg-fuchsia-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>fuchsia 400</code>
            </div>
            <div className="bg-fuchsia-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>fuchsia 500</code>
            </div>
            <div className="bg-fuchsia-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>fuchsia 600</code>
            </div>
            <div className="bg-fuchsia-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>fuchsia 700</code>
            </div>
            <div className="bg-fuchsia-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>fuchsia 800</code>
            </div>
            <div className="bg-fuchsia-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>fuchsia 900</code>
            </div>

            <div className="bg-pink-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>pink 50</code>
            </div>
            <div className="bg-pink-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>pink 100</code>
            </div>
            <div className="bg-pink-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>pink 200</code>
            </div>
            <div className="bg-pink-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>pink 300</code>
            </div>
            <div className="bg-pink-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>pink 400</code>
            </div>
            <div className="bg-pink-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>pink 500</code>
            </div>
            <div className="bg-pink-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>pink 600</code>
            </div>
            <div className="bg-pink-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>pink 700</code>
            </div>
            <div className="bg-pink-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>pink 800</code>
            </div>
            <div className="bg-pink-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>pink 900</code>
            </div>

            <div className="bg-rose-50 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>rose 50</code>
            </div>
            <div className="bg-rose-100 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>rose 100</code>
            </div>
            <div className="bg-rose-200 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>rose 200</code>
            </div>
            <div className="bg-rose-300 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>rose 300</code>
            </div>
            <div className="bg-rose-400 flex justify-center items-center text-2xl font-black p-8 rounded-xl text-black">
                <code>rose 400</code>
            </div>
            <div className="bg-rose-500 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>rose 500</code>
            </div>
            <div className="bg-rose-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>rose 600</code>
            </div>
            <div className="bg-rose-700 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>rose 700</code>
            </div>
            <div className="bg-rose-800 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>rose 800</code>
            </div>
            <div className="bg-rose-900 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>rose 900</code>
            </div>
        </div>
    );
}

export default AllColors;