import React from 'react';

function TitleColors() {
    return ( 
        <div className="grid md:grid-cols-6 sm:grid-cols-3 gap-5 text-center">
            <div className="bg-bluegray-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>bluegray</code>
            </div>
            <div className="bg-coolgray-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>coolgray</code>
            </div>
            <div className="bg-gray-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>gray</code>
            </div>
            <div className="bg-truegray-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>truegray</code>
            </div>
            <div className="bg-warmgray-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>warmgray</code>
            </div>
            <div className="bg-red-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>red</code>
            </div>
            <div className="bg-orange-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>orange</code>
            </div>
            <div className="bg-amber-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>amber</code>
            </div>
            <div className="bg-yellow-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>yellow</code>
            </div>
            <div className="bg-lime-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>lime</code>
            </div>
            <div className="bg-green-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>green</code>
            </div>
            <div className="bg-emerald-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>emerald</code>
            </div>
            <div className="bg-teal-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>teal</code>
            </div>
            <div className="bg-cyan-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>cyan</code>
            </div>
            <div className="bg-sky-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>sky</code>
            </div>
            <div className="bg-blue-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>blue</code>
            </div>
            <div className="bg-indigo-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>indigo</code>
            </div>
            <div className="bg-violet-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>violet</code>
            </div>
            <div className="bg-purple-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>purple</code>
            </div>
            <div className="bg-fuchsia-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>fuchsia</code>
            </div>
            <div className="bg-pink-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>pink</code>
            </div>
            <div className="bg-rose-600 flex justify-center items-center text-2xl font-black p-8 rounded-xl">
                <code>rose</code>
            </div>
        </div>
    );
}

export default TitleColors;