import React, { useState, useEffect } from 'react';
import Axios from 'axios';

import SectionTitle from './SectionTitle';
import UpdateCard from './UpdateCard';

const _PASSWORD = "%Bw=?mJ=P$W*t&f%bGcms5FyaXYAGqfAEvA6%A3q$sTV$4ZTzA^D3fB-wgDFvm2V3sdYpgA$5a*!ncT_2B#3_*s6CH&m4F#U";

function Updates ({ category }) {

    const [updateBlock, setUpdateBlock] = useState([]);
    const [stateText, setStateText] = useState('Loading...');

    const getCategory = (id) => {
        const categories = [
            "samp",
            "gtav",
            "web",
            "other"
        ];

        if(!categories.includes(id)) id = "all";

        switch (id) {
            case "samp":
                return "nlvl_samp";
            case "gtav":
                return "nlvl_gtav";
            case "web":
                return "nlvl_web";
            case "other":
                return "nlvl_other";
            case "all":
                return "nlvl_all";
            default:
                return "nlvl_none"
        }
    }

    useEffect(() => {
        async function request() {
            try {
                const cat = getCategory(category);
                const response = await Axios.post('/php/get_updates.php', { request_data: _PASSWORD, category: cat });
                if (response.data.fail || response.data.fail === undefined || response.data === "") throw new Error();
                else if (response.data.updates.length === 0) setStateText("No Updates Available.")
                else setUpdateBlock(response.data.updates);
            } catch (error) {
                setStateText("Unable to fetch updates.");
                return;
            }
        }
        request();
    }, [category]);

    if (updateBlock.length > 0) {
        return (
            <>
                <SectionTitle title="Latest Update" />
                <UpdateCard {...updateBlock[0]} />

                <SectionTitle title="Previous Updates" />
                {updateBlock.map((update, i) => (
                    i !== 0 && <UpdateCard key={i} {...update} />
                ))}
            </>
        )
    } else {
        return <h1 className="text-2xl" style={{ textAlign: 'center' }}>{stateText}</h1>
    }   
}

export default Updates;