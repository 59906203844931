import React, { useState } from 'react';

function UpdateCard ({projectName, color, isActive, developer, version, datetime, added, updated, removed, other, description = ""}) {
    const [isOpen, setOpen] = useState(isActive);

    
    let activeClassName = isOpen ? 
        "shadow-lg rounded-xl w-auto my-6 bg-white dark:bg-coolgray-800 relative overflow-hidden nlvl_collapse_content nlvl_active" :
        "shadow-lg rounded-xl w-auto my-6 bg-white dark:bg-coolgray-800 relative overflow-hidden nlvl_collapse_content";

        added = !added ? [] : added
        updated = !updated ? [] : updated
        removed = !removed ? [] : removed
        other = !other ? [] : other

    let hasAdded = added.length > 0, 
        hasUpdated = updated.length > 0, 
        hasRemoved = removed.length > 0, 
        hasOther = other.length > 0;

    return (
        <div className={activeClassName}>
            <div onClick={() => setOpen(!isOpen)} className={"h-16 flex justify-between items-center text-white nlvl_collapse_btn bg-" + color}>
                <h1 className="font-semibold p-4 text-lg">{projectName} - v{version}</h1>
                <span className="h-full bg-white bg-opacity-25 w-64 flex justify-end items-center" style={{ clipPath: 'polygon(10% 0, 100% 0, 100% 100%, 0% 100%)' }}>
                    <span className="text-white text-5xl mr-5">{isOpen ? (<>&#9662;</>) : (<>&#9656;</>)}</span>
                </span>
            </div>

            <div className="p-8 pt-4 overflow-auto nlvl_fix_height">
                <div className="flex items-center justify-between w-full border-b-2 mb-2 pt-2 pb-6">
                    <div className="w-72 flex items-center">
                        <img className='w-16 h-16 object-cover rounded-full' alt='User avatar' src={developer.image} />
    
                        <div className="pl-3">
                            <div className="font-bold text-2xl">
                                {developer.name}
                            </div>
                            <div className="text-gray-600 dark:text-gray-300 text-md font-medium">
                                {developer.rank}
                            </div>
                        </div>
                    </div>
    
                    <div>
                        <h1 className="text-gray-800 dark:text-white text-3xl font-black mb-2 text-right">
                            Version: {version}
                        </h1>
                        <p className="text-blue-600 dark:text-gray-50 text-md font-medium mb-2 text-right">
                            {datetime}
                        </p>
                    </div>
                </div>
                {description && (
                    <div className="w-full pt-4">
                        <h2 className="text-gray-800 dark:text-white text-2xl font-extrabold mb-2">Version Details</h2>
                        <p>{description}</p>
                    </div>
                )}
                {hasAdded && (
                    <div className="w-full pt-4">
                        <h2 className="text-gray-800 dark:text-white text-2xl font-extrabold mb-2">Added</h2>
                        <ul>
                            {added.map(item => (
                                <li key={item.id} className="py-1">{item.text}</li>
                            ))}
                        </ul>
                    </div>
                )}
                
                {hasUpdated && (
                    <div className="w-full pt-4">
                        <h2 className="text-gray-800 dark:text-white text-2xl font-extrabold mb-2">Updated</h2>
                        <ul>
                            {updated.map(item => (
                                <li key={item.id} className="py-1">{item.text}</li>
                            ))}
                        </ul>
                    </div>
                )}
                
                {hasRemoved && (
                    <div className="w-full pt-4">
                        <h2 className="text-gray-800 dark:text-white text-2xl font-extrabold mb-2">Removed</h2>
                        <ul>
                            {removed.map(item => (
                                <li key={item.id} className="py-1">{item.text}</li>
                            ))}
                        </ul>
                    </div>
                )}
                
                {hasOther && (
                    <div className="w-full pt-4">
                        <h2 className="text-gray-800 dark:text-white text-2xl font-extrabold mb-2">Other Changes</h2>
                        <ul>
                            {other.map(item => (
                                <li key={item.id} className="py-1">{item.text}</li>
                            ))}
                        </ul>
                    </div>
                )}
                
            </div>
        </div>
    );
}

export default UpdateCard;