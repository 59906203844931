import { useState } from 'react';

import Header from './Header';
import Footer from './Footer';
import SectionTitle from './SectionTitle';
import TitleColors from './TitleColors';
import AllColors from './AllColors';

function Colors() {

    const _PASSWORD = 'shaneisac';

    const [isLocked, setIsLocked] = useState(true);
    const [password, setPassword] = useState('');

    function onPasswordChange(e) {
        setPassword(e.target.value);
        console.log(password)
        password === _PASSWORD && setIsLocked(!isLocked);
    }

    function showGateway () {
        return (
            <div>
                <div className="flex justify-center items-center flex-col">
                    <h2 className="mb-5 p-5 text-center font-black text-2xl">Enter password to continue...</h2>
                    <form className="w-1/2" onSubmit={(e) => e.preventDefault()}>
                        <input onFocus={() => setPassword('')} onChange={(e) => onPasswordChange(e)} className="p-5 text-2xl text-white bg-coolgray-800 rounded-xl text-center w-full mb-5 focus:ring-2 focus:ring-indigo-700" type="password" autoFocus />
                        {/* <button className="text-2xl font-black flex items-center justify-center focus:ring-2 focus:ring-indigo-700 bg-indigo-700 hover:bg-indigo-600 active:bg-indigo-800 p-5 w-full rounded-xl text-center ">Authorize</button> */}
                    </form>
                </div>
            </div>
        );
    }

    function showColors () {
        return (
            <div>
                <SectionTitle title="Available Title Colors (600)" />
                <TitleColors />

                <SectionTitle title="All Colors" />
                <AllColors />
            </div>
        );
    }

    return (
        <div className="container mx-auto">
            <Header />
                {isLocked ? showGateway() : showColors()}
            <Footer />
        </div>
    );
}

export default Colors;