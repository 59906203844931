import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import Changelog from './components/Changelog';
import Colors from './components/Colors';
import Category from './components/Category';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Changelog} />
          <Route exact path="/colors" component={Colors} />
          <Route exact path="/category/:id" component={Category} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
